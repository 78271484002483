let apiBaseUrl = "";
let siteUrl = "";
let appStage = process.env.REACT_APP_STAGE;

if (appStage == "dev") {
  // apiBaseUrl = "https://c29e-103-76-82-153.ngrok.io";
  // siteUrl = "https://c29e-103-76-82-153.ngrok.io";
  apiBaseUrl = "https://api.rcfeadmin.com:3097";
  siteUrl = "https://api.rcfeadmin.com";

  // apiBaseUrl = 'https://swapi1.glohtesting.com' // previous-api-url
  // apiBaseUrl = 'https://swapi1.dreamztesting.com/'
  // siteUrl = 'https://swapi1.glohtesting.com'
  // apiBaseUrl = 'http://localhost:3087';
  // siteUrl = 'http://localhost:3087';

} else if (appStage == "stage") {
  apiBaseUrl = "https://swapi1.glohtesting.com:3097";
  siteUrl = "https://swapi1.glohtesting.com:3097";
  // apiBaseUrl = "https://api.rcfeadmin.com:3097";
  // siteUrl = "https://api.rcfeadmin.com";
} else if (appStage == "prod") {
  apiBaseUrl = "https://api.rcfeadmin.com:3097";
  siteUrl = "https://api.rcfeadmin.com";
}

export const API_BASE_URL = apiBaseUrl;
export const SITE_BASE_URL = siteUrl;
